<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <v-btn
            color="success"
            class="mr-2"
            dark
            @click="openDialog()"
          >
            <v-icon
              left
              v-text="'mdi-plus'"
            />
            Adicionar
          </v-btn>

          <v-btn
            v-if="hasPermission('roles')"
            to="/grupos"
            color="black"
            class="mr-2"
            outlined
          >
            <v-icon
              left
              v-text="'mdi-account-group'"
            />
            Grupos
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-filtering
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="users"
              :options.sync="pagination"
              :footer-props="{
                'items-per-page-options': [10, 20, 50]
              }"
              @update:options="loadUsers"
            >
              <template v-slot:[`item.active`]="{ item }">
                {{ item.active ? 'Sim' : 'Não' }}
              </template>
              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  @click="openDialogChangePassword(item)"
                >
                  <v-icon
                    v-text="'mdi-lock-reset'"
                    small
                  />
                </v-btn>

                <v-btn
                  icon
                  small
                  @click="openDialog(item)"
                >
                  <v-icon
                    v-text="'mdi-pencil'"
                    small
                  />
                </v-btn>

                <v-btn
                  icon
                  small
                >
                  <v-icon
                    v-text="'mdi-delete'"
                    small
                    @click="clickDeleteItem(item)"
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-delete
      v-model="dialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
    />

    <dialog-user 
      v-model="dialogUser"
      :user-id="editId"
      :key="`edit-${editId}`"
      @save="loadUsers"
    />

    <dialog-user-change-password
      v-model="dialogUserChangePassword"
      :user-id="changePasswordId"
      :key="`change-password-${changePasswordId}`"
    />

  </div>
</template>

<script>
  import usersApi from '@/api/users'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      DialogDelete: () => import('@/components/dialog/DialogDelete'),
      DialogUser: () => import('@/components/dialog/users/DialogUser'),
      DialogUserChangePassword: () => import('@/components/dialog/users/DialogUserChangePassword')
    },

    data () {
      return {
        loading: false,
        loadingDelete: false,
        dialogDelete: false,
        dialogUser: false,
        dialogUserChangePassword: false,
        count: 0,
        deleteId: null,
        editId: null,
        changePasswordId: null,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Nome', value: 'name' },
          { text: 'Usuário', value: 'username' },
          { text: 'Ativo', value: 'active' },
          { text: '', value: 'actions', align: 'right' },
        ],
        users: [],
        pagination: {},
      }
    },

    computed: {
      ...mapGetters({
        hasPermission: 'user/hasPermission',
      })
    },

    methods: {

      async loadUsers () {
        try {
          this.loading = true

          const response = await usersApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
          })

          this.users = response.data.users
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialog (user = null) {
        this.editId = user ? user.id : null
        this.dialogUser = true
      },

      openDialogChangePassword (user = null) {
        this.changePasswordId = user ? user.id : null
        this.dialogUserChangePassword = true
      },

      async clickDeleteItem (user) {
        this.deleteId = user.id
        this.dialogDelete = true
      },

      async deleteItem () {
        try {
          this.loadingDelete = true

          await usersApi.delete(this.deleteId)

          this.loadUsers()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.dialogDelete = false
        }
      },

    },

  }
</script>
